<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche utilisateur {{user.prenom}} {{user.nom}}</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-form-group label="Nom:">
            <b-form-input v-model="user.nom" type="text" required placeholder="Entrez le nom"></b-form-input>
          </b-form-group>
          <b-form-group label="Prenom:">
            <b-form-input v-model="user.prenom" type="text" required placeholder="Entrez le prenom"></b-form-input>
          </b-form-group>
          <b-form-group label="Email:">
            <b-form-input v-model="user.email" type="text" required placeholder="Entrez l'email"></b-form-input>
          </b-form-group>
          <b-form-group label="Telephone:">
            <b-form-input v-model="user.telephone" type="text" required placeholder="Entrez le telephone"></b-form-input>
          </b-form-group>
          <b-form-group label="Mot de passe:">
            <b-form-input v-model="user.password" type="text" required placeholder="Entrez le mot de passe"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label>Role:</label>
            <select class="form-control" v-model="user.role">
              <option v-for="(r,i) in parametrage.role" :value="r.id" :key="i">{{r.titre}}</option>
            </select>
          </div>
          <b-form-group label="Quotas lead quotidien:">
            <b-form-input v-model="user.quotas.total" type="number" required placeholder="Entrez le quotas quotidien de distribution de lead"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label>Statut:</label>
            <select class="form-control" v-model="user.status">
              <option v-for="(s,i) in parametrage.status" :value="s.id" :key="i">{{s.titre}}</option>
            </select>
          </div>
        </b-form>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Commission</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="addCommission()">
            <i class="icon-md fas fa-plus"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Minimum</th>
              <th scope="col">Maximum</th>
              <th scope="col">%</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c,i) in user.commissions" :key="i">
              <td>
                <b-form-input v-model.number="c.min" type="number" required placeholder="Entrez une somme minimum" readonly></b-form-input>
              </td>
              <td>
                <b-form-input v-model.number="c.max" type="number" required placeholder="Entrez une somme Maximum"></b-form-input>
              </td>
              <td>
                <b-form-input v-model.number="c.val" type="number" required placeholder="Entrez le nom"></b-form-input>
              </td>
              <td>
                <b-button variant="danger" size="sm" class="btn-icon mr-1" @click="removeCommission(i)"><i class="icon-md fas fa-trash"></i></b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      user:{
        id: null,
        nom: null,
        prenom: null,
        email: null,
        telephone:null,
        password: null,
        role: null,
        status: null,
        quotas:{
          count:null,
          total:null
        },
        commissions:[]
      },
      parametrage:{
        role:[],
        status:[]
      }
    }
  },
  components: {
  },
  watch: {
  },
  methods: {
    save() {
      this.$store.back.ajax('/users/update', this.user, (res) => {
        if(res.status === true){
          this.user = res.data;
          this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    addCommission(){
      let tmp = {
        min:0,
        max:0,
        val:0
      };
      if(this.user.commissions.length > 0){
        var old = this.user.commissions[this.user.commissions.length - 1];
        tmp.min = old.max;
      }
      this.user.commissions.push(tmp);
    },
    removeCommission(i){
      this.user.commissions = this.user.commissions.slice(0,i)
    }
  },
  computed: {

  },
  beforeMount(){
    let params = {
      modules: ["UserRole"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.role = res.data['UserRole'];
        this.parametrage.status = res.data['status'];
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);

    this.$store.back.ajax(`/users/${this.$route.params.id}`, null, (res) => {
      if(res.status === true){
        this.user = res.data;
      }
    });
  }
};
</script>
